import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../templates/layout'
import Column2 from '../templates/column2'
import Header from '../organisms/header'
import Footer from '../organisms/footer'
import MainContent from '../organisms/main-content'
import Sidebar from '../organisms/sidebar'
import PostList from '../organisms/post-list'
import Pagination from '../organisms/pagination'
import SEO from '../utils/seo'

export default ({ pageContext, data }) => {
  const { currentPage, totalPages, canonical, tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const isFirst = currentPage === 1
  const title = isFirst
    ? `${tag.id}の記事一覧`
    : `${tag.id}の記事一覧 ${currentPage}ページ目`
  const headerTitle = isFirst
    ? `${tag.id}の記事：${totalCount}件`
    : `${tag.id}の記事：${totalCount}件（${currentPage}ページ目）`

  // Create breadcrumb list
  const breadcrumbList = [
    { name: 'ikapro', item: 'https://ikapro.tech' },
    { name: tag.id, item: canonical },
  ]

  return (
    <Layout>
      <SEO title={title} canonical={canonical} />
      <Helmet
        script={[
          {
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'BreadcrumbList',
              itemListElement: breadcrumbList.map(({ item, name }, i) => ({
                '@type': 'ListItem',
                position: i + 1,
                name: name,
                item: item,
              })),
            }),
          },
        ]}
      />
      <Header />
      <Column2>
        <MainContent>
          <PostList posts={edges} headerTitle={headerTitle} />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            pathPrefix={`/blog/tags/${tag.slug}`}
            firstPagePath={`/blog/tags/${tag.slug}`}
          />
        </MainContent>
        <Sidebar />
      </Column2>
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!, $skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      skip: $skip
      limit: $limit
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { elemMatch: { slug: { eq: $slug } } } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date
            description
            tags {
              id
              slug
            }
          }
          id
          excerpt
        }
      }
    }
  }
`
